import request from "@/utils/request"

// 获取空间菜单
export function menus (params) {
  return request({
      url: `/manage/space/${params.spaceId}/menus`,
      method: "get",
      // params
  })
}
// 获取应用资源
export function get_apply_source (params) {
  return request({
      url: `/manage/space/${params.id}`,
      method: "get",
      // params
  })
}
// 创建空间菜单
export function add_space_menu (data) {
  return request({
      url: `/manage/space/${data.spaceId}/menu`,
      method: "post",
      data
  })
}
// 空间菜单.向下移动顺序
export function menu_down (params) {
  return request({
      url: `/manage/space/menu/down`,
      method: "get",
      params
  })
}
// 空间菜单.向上移动顺序
export function menu_up (params) {
  return request({
      url: `/manage/space/menu/goup`,
      method: "get",
      params
  })
}
// 删除空间菜单
export function remove_menu (params) {
  return request({
      url: `/manage/space/menu/${params.spaceMenuId}`,
      method: "delete",
      // params
  })
}
// 空间菜单绑定绑定应用资源
export function menus_bind_apply (params) {
  return request({
      url: `/manage/space/menu/bind`,
      method: "put",
      params
  })
}
// 查询.空间APP
export function menus_apply (params) {
  return request({
      url: `/manage/space/${params.spaceId}/app`,
      method: "get",
      // params
  })
}
// 根据空间菜单ID查找绑定的租户, 若为空则返回空
export function menu_binded_tenant (params) {
  return request({
      url: `/manage/space/app/find`,
      method: "get",
      params
  })
}
// 查询.空间下的所有(租户)
export function tenants (params) {
  return request({
      url: `/manage/space/${params.spaceId}/tenant`,
      method: "get",
      // params
  })
}
//查询.某租户下所有的资源
export function tenant_sources (params) {
  return request({
      url: `/manage/tenant/permission/${params.tenantId}`,
      method: "get",
      // params
  })
}
