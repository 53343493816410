<template>
  <div class="appContainer spaceBox flex-between">
    <div class="leftBox bb">
      <div class="header fW600 flex-start">空间名：{{ spaceName }}</div>
      <leftTree
        ref="treeDom"
        :treeData="treeData"
        @click_left_node="left_node_fn"
        :spaceId="spaceId"
      />
    </div>
    <div class="rightBox bb">
      <div class="header flex-between">
        <span class="fW600"
          >绑定资源
          <label
            v-if="selectLeftNode"
            style="
              font-weight: normal;
              margin-left: 10px;
              color: rgb(106 159 241);
            "
            >(当前菜单：{{ selectLeftNode.name }})</label
          >
        </span>
        <el-button
          type="primary"
          :disabled="selectLeftNode && applySourceList.length ? false : true"
          @click="saveFn"
          >保存</el-button
        >
      </div>
      <!-- 右侧 -->
      <el-tabs
        v-model="activeName"
        @tab-click="tabFn"
        class="tab_box"
        v-if="tabs.length && selectLeftNode"
      >
        <el-tab-pane
          :label="cell.name"
          :name="cell.name"
          v-for="cell in tabs"
          :key="cell.id"
        >
          <el-table
            v-if="cell.name == activeName"
            :stripe="false"
            :data="applySourceList"
            ref="table"
            style="width: 100%"
            row-key="code"
            :max-height="tableHeight + 'px'"
            :indent="60"
            border
            :default-expand-all="true"
            :header-cell-style="{ 'text-align': 'left ' }"
            :cell-style="{ 'text-align': 'left ' }"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
            :row-style="tableRowStyle"
          >
            <el-table-column label="资源编号" prop="code">
              <template slot-scope="{ row }">
                <span
                  v-if="row.parentId == undefined"
                  class="db"
                  :style="{
                    'margin-top': row.children.length ? '-20px' : '0',
                    'margin-left': '20px'
                  }"
                  >{{ row.code }}</span
                >
                <span
                  v-else
                  style="margin-left: 50px; margin-top: -20px"
                  class="db"
                >
                  {{ row.code }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="资源名称">
              <template slot-scope="{ row, $index }"
                ><span class="di" :ref="'tableRow' + $index">{{
                  row.name
                }}</span></template
              ></el-table-column
            >
            <el-table-column label="资源描述" prop="description" />
            <el-table-column label="操作" width="80px">
              <template slot-scope="{ row }">
                <!-- v-if="!row.children" -->
                <el-radio
                  :disabled="row.parentNode == 'none'"
                  v-model="selectRadio"
                  :label="row.code"
                  @click.native.prevent="clickRadioFn(row.code, row)"
                  ><span
                /></el-radio>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>
      <el-empty
        v-if="tabs.length == 0 && selectLeftNode"
        style="margin-top: 200px"
        description="暂无数据，请先绑定应用"
      />
      <el-empty
        v-if="!selectLeftNode"
        style="margin-top: 200px"
        description="暂无数据，请点击左侧列表"
      />
    </div>
  </div>
</template>

<script>
import {
  menus,
  menus_bind_apply,
  // menus_apply,
  menu_binded_tenant,
  tenant_sources,
  tenants
} from '@/api/space_menus';

export default {
  name: 'SpceMenu',
  components: {
    leftTree: () => import('./components/index_left.vue')
  },
  data() {
    return {
      spaceName: '',
      spaceId: null,
      treeData: [],
      activeName: '',
      tabs: [],
      applySourceList: [],
      selectRadio: null,
      selectLeftNode: null,
      currentTabApplyTableData: {}, //当前应用表格数据
      currentRowData: {},
      appAllData: {},
      tableHeight: window.innerHeight - 300,
      loading: false,
      currentSelectRowIndex: 0,
      expandNodes: [],
      ifFreshExpandNodes: true,
      currentSelectPermissionId: ''
    };
  },
  mounted() {
    this.spaceName = unescape(this.$route.params.name);
    this.spaceId = this.$route.params.spaceId;
    this.get_meus();
    this.getTabTenantList(() => {});
    let that = this;
    window.onresize = () => {
      return (() => {
        that.tableHeight = window.innerHeight - 300;
        // console.log(this.tableHeight, 'maxHeight===', window.innerHeight)
      })();
    };
  },

  methods: {
    tableRowStyle({ row }) {
      let rowBackground = {};
      if (row.code == this.selectLeftNode.spacePermissionCode) {
        //   // 设置条件
        rowBackground.background = '#d7e3f5';
      }
      return rowBackground;
    },
    setApplysList(arr) {
      this.appAllData = {};
      if (!arr.length) return;
      arr.forEach(cell => {
        this.appAllData[cell.appId.toString()] = cell;
      });
    },
    //回显选择
    filterSelectRadio(arr) {
      if (!this.selectLeftNode) return '';
      let a = arr.filter((cell, key) => {
        if (cell.code == this.selectLeftNode.spacePermissionCode) {
          this.currentSelectRowIndex = key;
          // console.log(key, 'key----')
        }
        return cell.code == this.selectLeftNode.spacePermissionCode;
      });
      if (a.length) {
        this.currentSelectPermissionId = a[0].tenantPermissionId.toString();
      }
      return a.length ? a[0].code + '' : '';
    },
    filterTabData() {
      let a = this.tabs.filter(ele => {
        return ele.name == this.activeName;
      });
      return a.length ? a[0] : null;
    },
    tabFn() {
      this.currentTabApplyTableData = this.filterTabData();
      if (!this.currentTabApplyTableData) {
        return;
      }
      this.get_source_table();
    },
    async menu_binded_tenant(cb) {
      const res = await menu_binded_tenant({
        spaceMenuId: this.selectLeftNode.spaceMenuId.toString()
      });
      if (res.data != '') {
        this.activeName =
          res.name || (this.tabs.length && this.tabs[0].name) || '';
      } else {
        this.activeName = this.tabs.length ? this.tabs[0].name : '';
      }
      cb && cb();
      // console.log(this.activeName, '----------绑定租户名称')
    },
    left_node_fn(v) {
      this.selectRadio = null;
      this.selectLeftNode = v;
      console.log(v, '左边');
      if (!v) return;
      this.menu_binded_tenant(() => {
        this.currentTabApplyTableData = this.filterTabData();
        if (!this.currentTabApplyTableData) {
          return;
        }
        this.get_source_table();
      });
    },

    //应用表格数据
    async get_source_table(cb) {
      const tenantId = this.currentTabApplyTableData.tenantId.toString();
      const res = await tenant_sources({ tenantId: tenantId });
      this.applySourceList =
        Object.values(this.set_source_table_data(res)) || [];
      // console.log(this.applySourceList, 'this.applySourceList');
      if (res.length) {
        this.selectRadio = this.filterSelectRadio(res);
      }
      this.$nextTick(() => {
        if (this.selectRadio) {
          if (!this.$refs['table'][0]) return; //不存在这个表格则返回
          let elTable = this.$refs['table'][0].$el;
          if (!elTable) return;
          const scrollParent = elTable.querySelector('.el-table__body-wrapper');
          const rowTop =
            elTable.querySelectorAll('.el-table__body tr')[
              this.currentSelectRowIndex
            ] &&
            elTable
              .querySelectorAll('.el-table__body tr')
              [this.currentSelectRowIndex].getBoundingClientRect().top; //该行的位置
          const containerTop = elTable
            .querySelector('.el-table__body')
            .getBoundingClientRect().top; //body的位置
          setTimeout(() => {
            scrollParent.scrollTop = rowTop - containerTop;
          });
        }
      });
    },

    set_source_table_data(arr) {
      let newList = {};
      let noParentNodes = {
        name: '未知资源',
        children: [],
        code: '未知code',
        parentNode: 'none'
      };
      arr.forEach(ele => {
        let obj = {};
        obj = ele;
        obj.rootId = ele.rootId.toString();
        obj.tenantPermissionId = ele.tenantPermissionId.toString();
        obj.tenantId = ele.tenantId.toString();
        if (ele.parentId == undefined) {
          //一级
          obj.children = [];
          newList[obj.rootId] = obj;
        } else {
          if (newList[ele.parentId]) {
            obj.parentId = ele.parentId.toString();
            newList[ele.parentId].children.push(obj);
          } else {
            obj.parentNode = 'none';
            noParentNodes.children.push(obj);
          }
        }
      });
      return noParentNodes.children.length
        ? { ...newList, noParentNodes }
        : newList;
    },

    //该空间租户
    async getTabTenantList(cb) {
      const res = await tenants({
        spaceId: this.spaceId
      });
      this.tabs = res || [];
      cb && cb();
      // console.log(this.tabs, 'tabs===')
    },
    //空间菜单
    async get_meus(cb) {
      const res = await menus({ spaceId: this.spaceId });
      // this.treeData = Object.values(this.setTreeData(res));
      let a = Object.values(this.setTreeData(res));

      if (a.length >= 1) {
        a.forEach(cell => {
          if (cell.children && cell.children.length > 1) {
            cell.children = this.sortByKey(cell.children, 'priority');
          }
        });
        this.treeData = this.sortByKey(a, 'priority');
      }
      // console.log(this.treeData, ' this.treeData===');
      cb && cb();
    },
    sortByKey(array, key) {
      return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        return x > y ? (x < y ? 1 : 0) : -1;
      });
    },
    setTreeData(arr) {
      let newList = {};
      arr.forEach(ele => {
        // console.log(ele)
        let obj = ele;
        obj.spaceMenuId = obj.spaceMenuId.toString();
        if (ele.parentId == undefined) {
          //一级
          obj = ele;
          obj.children = [];
          newList[obj.spaceMenuId] = obj;
        }
      });
      arr.forEach(ele => {
        ele.spaceMenuId = ele.spaceMenuId.toString();
        if (ele.parentId && newList[ele.parentId.toString()]) {
          if (this.ifFreshExpandNodes) {
            this.expandNodes.push(ele.spaceMenuId);
          }
          ele.parentId = ele.parentId.toString();
          newList[ele.parentId].children.push(ele);
        }
      });
      this.ifFreshExpandNodes = !this.ifFreshExpandNodes;
      return newList;
    },
    clickRadioFn(e, row) {
      // console.log(row, 'row===')
      this.currentRowData = row;
      e == this.selectRadio ? (this.selectRadio = '') : (this.selectRadio = e);
    },
    // 绑定
    saveFn() {
      // return
      if (!this.selectRadio) {
        this.$message.warning('请选择要绑定的应用资源');
        return;
      }
      // console.log(this.currentRowData, this.selectRadio);
      // return;
      this.menus_bind_apply(() => {
        this.$message.success('绑定成功');
        this.selectLeftNode.spacePermissionCode = this.selectRadio;
        this.get_meus();
      });
    },
    async menus_bind_apply(cb) {
      const res = await menus_bind_apply({
        spaceMenuId: this.selectLeftNode.spaceMenuId.toString(),
        tenantPermissionId: this.currentRowData.tenantPermissionId
          ? this.currentRowData.tenantPermissionId.toString()
          : this.currentSelectPermissionId
      });
      cb && cb();
    }
  }
};
</script>

<style lang="scss" scoped>
.header {
  height: 40px;
  padding: 10px 0;
  border-bottom: 1px solid #e5e5e5;
}
.tab_box {
  ::v-deep {
    .el-tabs__nav-wrap::after {
      height: 0;
    }
    .el-tabs__header {
      margin-top: 15px;
    }
  }
}
.empty {
  border: 1px solid #dfe6ec;
  padding: 20px;
  margin-top: -1px;
}
</style>
